import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'
export const AdditionalappfeaturesecStyles = styled.div`
  margin-bottom: 120px; 
  position: relative;
  ${xl(`   
    margin-bottom: 100px; 
  `)}
  ${lg(`
    margin-bottom: 60px; 
  `)}
  ${md(`
    margin-bottom: 40px; 
  `)}
  .additionalAppfeatureHead {
    padding-bottom:70px;
    ${lg(` 
        padding-bottom:50px;
    `)}
    ${md(` 
        padding-bottom:40px;
    `)}
  }
  .mobielAppScreenDetails { 
      max-width: 100%;
      padding-bottom:0px;
    ${lg(`
          padding-bottom:0;
      `)}
    .secRow {
      display: flex;  
      align-items:center; 
      margin-bottom:130px;
      padding:0px;
      ${xl(`
        margin-bottom:100px;
      `)}
      ${lg(`
          flex-direction: column;  
          margin-bottom:70px;
      `)}
      ${md(`
          flex-direction: column;  
          margin-bottom: 40px;
      `)}
      &:last-child { 
        margin-bottom: 0;
      }
      &:nth-child(even) {
        .secContentCol {
          order: 2; 
          padding-left: 20px; 
          ${lg(`
              padding:0 0 30px; 
              flex:1 1 auto;
            `)}
          ${md(`
            padding:0 0 30px;  
            flex:1 1 auto;
          `)}
        }
        .secImgCol {  
          padding:0px 20px 0 0; 
          ${lg(`
            padding: 0 00px;
            order: 2;
          `)}
        }  
        &.is-hidden {
          .secImgCol{
            opacity: 0;
            will-change: transform, opacity;
            transform: translate3d(-140px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg)
              rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          }
          .secContentCol {
            opacity: 0;
            will-change: transform, opacity;
            transform: translate3d(140px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg)
              rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          }
        } 
      } 
      &.is-hidden {
        .secContentCol{
          opacity: 0;
          will-change: transform, opacity;
          transform: translate3d(-140px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        }
        .secImgCol{
          opacity: 0;
          will-change: transform, opacity;
          transform: translate3d(140px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        }
      }
      &.is-visible {
        .secContentCol{
          will-change: transform, opacity;
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            opacity: 1;
        }
        .secImgCol{
          will-change: transform, opacity;
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            opacity: 1;
        }
      }
      .secContentCol { 
        padding:0px 20px 0 0; 
        flex:0 1 50%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        transform-style: preserve-3d;
        transition: all 0.8s ease-in-out;
        transition-delay: 0.5s; 
        ${lg(`
          padding: 0;
          order: 1;
          padding-bottom:30px;
          flex:1 1 auto;
        `)}   
        p {
          &:last-child {
            margin: 0px;
          }
        }
      }
      .secImgCol { 
        margin:0px;
        padding:0px 0 0 20px; 
        flex:0 1 50%; 
        transform-style: preserve-3d;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.6s;
        ${lg(`
          margin:0;
          padding: 0;
          order: 2; 
          width:100%;
          flex:1 1 auto;
        `)}
      }
    }
}
`
