import React from 'react'

import { CaseteamingupsecStyles } from './Caseteamingupsec.styles'
import Image from 'components/ImgOptimized'

function Caseteamingupsec(props) {
  const {
    caseTeamingUpData: { caseTeamingUpProps },
  } = props
  return (
    <CaseteamingupsecStyles>
      <div className="teamingUpDetailsTopRow">
        <div className="container">
          <div className="teamingUpDetailsRow">
            <div className="detailsLeft">
              <h2>{caseTeamingUpProps.title}</h2>
              <p>
                <span>{caseTeamingUpProps.bottomInfo}</span>
              </p>
            </div>
            <div className="detailsRight">
              <p>{caseTeamingUpProps.para}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="teamResultSecRow">
        <div className="bottomRowImg">
          <Image
            fluid={props.teamBackImg}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 218,
            }}
          />
        </div>
        <div className="resultSecBoxRow">
          <div className="container">
            <div className="resultSecBox">
              <div className="resultSecBoxLeft">
                <h2>{caseTeamingUpProps.resultTitle}</h2>
              </div>
              <div className="resultSecBoxRight">
                <p>{caseTeamingUpProps.resultPara}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CaseteamingupsecStyles>
  )
}

export default Caseteamingupsec
