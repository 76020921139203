import styled from 'styled-components'
import { primaryColor, xl, lg, md } from 'config/variables'

export const PartnerprofileStyles = styled.div`
  margin-bottom: 107px;
  ${lg(`
      margin-bottom: 90px;
  `)}
  ${md(`
      margin-bottom: 40px;
  `)} 
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 47px;
    ${lg(`
           margin-bottom: 30px;
      `)}
    ${md(`
          flex-direction:column;
          align-items: flex-start;
          margin-bottom: 25px;
      `)}
      
    .title-part {
      align-self: flex-start;
      flex:1 0 auto;
      ${md(`
          margin-bottom: 7px;
      `)}
      .subtitle {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
      h3 {
        font-size: 48px;
        line-height: 61px;
        color: #8cc95b;
        margin: 0;
      }
    }

    .logos-part {
      display: flex;
      padding-left: 45px;
      align-items:flex-end;
      flex:1 1 auto; 
      ${xl(`
          padding-left: 30px;
      `)}
      ${lg(`
          padding-left: 30px;
          flex-wrap: wrap;
      `)}
        ${md(`
         padding-left: 0;
         width:calc(100% + 13px);
         margin-left:-13px;
      `)}
      figure {
        margin: 0 22px;
        width:auto;
        ${xl(`
         margin: 0 9px;
      `)}
      ${lg(`
           margin: 15px 13px;
      `)}
      }
    }
  }
  .statistics-blk {
    display: flex;
    justify-content: space-between;
    ${md(`
          flex-direction:column;
          align-items:center;
      `)}
    .single-blk {
      max-width: 220px;
      width: 100%;
      border-top: 3px solid ${primaryColor};
      padding: 32px 20px 15px;
      text-align: center;
      &:first-child {
       ${lg(`
          margin-left: 0; 
      `)}
      }
      &:last-child {
       ${lg(`
          margin-right: 0; 
      `)}
      }
      ${lg(`
          margin: 0 10px; 
          padding: 25px 10px 15px;
      `)}
       ${md(`
          padding:20px;
          margin:0px;
          max-width:190px;
      `)}
      .count {
        font-size: 48px;
        line-height: 65px;
        letter-spacing: -0.15px;
        margin-bottom: 12px;
        display: block;
        ${lg(`
           font-size: 40px;
           line-height: 50px;
        `)}
        ${md(`
           font-size: 32px;
           line-height: 44px;
        `)}
      }
      .title {
        font-size: 15px;
        line-height: 21px;
        letter-spacing: -0.16px;
        text-transform: uppercase;
        font-weight: 600;
        ${md(`
           font-size: 14px;
           line-height: 20px;
        `)}
      }
    }
  }
`
