import styled from 'styled-components'
import { images } from 'config/images'
import { md, lg, xl, primaryColor, white } from 'config/variables'
export const CaseteamingupsecStyles = styled.div` 
  margin-bottom: 73px;  
  ${xl(`   
    margin-bottom: 70px;  
  `)}
  ${lg(`
    margin-bottom: 50px;  
  `)}
  ${md(`
    margin-bottom: 40px;  
  `)} 
  .teamingUpDetailsTopRow {
    .container {
      max-width:1148px;
    }
  }
  .teamingUpDetailsRow {
    display:flex;
    ${md(`
         flex-direction: column;
     `)} 
    .detailsLeft {
      max-width:463px;
      padding-right:15px;
      padding-left:92px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between; 
      ${md(`
         max-width:100%;
         padding-right:0;
         padding-left: 37px;
     `)}
     
      &::after {
        content: "";
        position: absolute;
        top:0;
        left:0;
        height:45px;
        width:63px;
        background:url(${images.lgQuoteGray}); 
        ${md(`
            height: 21px;
            width: 30px;
            background-size:cover;
        `)}
      }
      h2 {
        font-size:40px;
        line-height: 1.2;
        letter-spacing: -0.43px;
        color: ${primaryColor};
        ${md(`
            font-size: 36px;
            line-height: 42px; 
        `)}
      }
      p {
        font-size: 15px;
        line-height:1;
        font-weight: 600;
        letter-spacing: -0.16px;
        color: ${primaryColor};
        position: relative;
        padding-top:21px;
        &::after {
          content: "";
          position: absolute;
          top:0;
          left:0;
          height: 3px;
          background-color: ${primaryColor};
          width:55px; 
        }
        ${md(`
          display:none;
        `)} 
      }
    }
    .detailsRight {
      max-width:606px;
      padding-left:15px;
      ${md(`
         max-width:100%;
         padding-left:0;
     `)}
    }
  }

  .resultSecBoxRow {
    position: absolute;
    top:90px;
    left:0;
    width:100%;
    ${lg(`
        position: static;
    `)} 
    .container {
      max-width: 1148px;
    }
  }
  .teamResultSecRow {  
      position: relative;
      min-height:746px;
      ${lg(`
          min-height:auto;
          padding:60px 0;
      `)} 
      ${md(`
          min-height:auto;
          padding:40px 0;
      `)} 
      .bottomRowImg {
        ${lg(`
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:-1;
        `)} 
        .gatsby-image-wrapper {
          max-width:100% !important;
          min-height:746px;
          ${lg(`
             min-height:100%; 
        `)} 
        }
      }
      &::after {
        content:"";
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background:url(${images.roundrResultHomeTop}) no-repeat   center; 
        background-size: cover;
        z-index:1;
        ${lg(`
            display:none;
        `)} 
      }
      .resultSecBox { 
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0.75);
        display:flex;
        padding:44px 73px;
        ${lg(`
            padding:40px 40px;
        `)} 
        ${md(`
             flex-direction: column;
             padding:20px;
             border-radius: 20px;
             background-color: rgba(0, 0, 0, 0.80);
        `)}
        .resultSecBoxLeft {
          flex:0 0 259px;
          ${lg(`
            flex:0 0 160px;
          `)} 
          ${md(`
             flex:1 1 auto;
        `)}
        }
        .resultSecBoxRight {
            flex:1 1 auto;
        }
        h2, p {
          color: ${white};
          position: relative;
          z-index:10;
        }
      }
  }
`
