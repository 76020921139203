import styled from 'styled-components'
import { xl, lg, md, sm } from 'config/variables'

export const IhealthresultsecStyles = styled.div`

.intro-video-section {
    width: 100%;
    position: relative;
    margin-bottom: 130px;
    ${xl(`
      margin-bottom: 100px;
    `)}
     ${lg(`
      margin-bottom: 90px;
    `)}
     ${md(`
      margin-bottom: 40px;
    `)}
    video {
      width:100%;
      height:auto;
    }
}
.result-sec {
    display: flex;
    justify-content: space-between;
    margin-bottom:30px;
    ${xl(`
        margin-bottom:20px;
      `)}
    ${lg(`
        margin-bottom:20px;
    `)}
    ${md(`
        flex-direction: column;
        margin-bottom:20px;
      `)}
    .left-part {
      max-width: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ${xl(`
        padding-right: 40px;
      `)}
      ${lg(`
        max-width: 152px;
        padding-right: 27px;
      `)}
      ${md(`
        max-width:100%;
      `)}
      
    }
    .right-part {
      max-width: 867px;
      width: 100%;
    }
  }
  .appListRow {
    display:flex;
    margin-bottom:138px;
    box-shadow: 0 5px 10px 0 #a1afdd17;
    border: solid 1px #e8e9f3;
    background-color: #ffffff80;
    ${xl(`
        margin-bottom:100px;
    `)}
    ${lg(`
        margin-bottom:90px;
    `)}
     ${md(`
        flex-direction:column;
        margin-bottom:68px;
    `)}
    .appList {
       flex:0 1 25%;
       border-right: solid 1px #e8e9f3; 
       text-align:center;
       padding:40px 20px 26px;
       ${xl(`
            padding:40px 10px 26px;
        `)}
        ${lg(`
            padding:29px 7px 19px;
        `)}
        ${lg(`
            padding:30px 15px 20px;
        `)}
        ${md(`
            flex:1 1 auto;
            border-right:none;
            border-bottom: solid 1px #e8e9f3; 
        `)}
       &:last-child {
           border-right:none;
           border-bottom:none;
       }
       .appIcon {
           padding-bottom:20px;
           img {
            max-width:56px;     
            ${md(`
                max-width:76px; 
            `)} 
           }
       }
       p {
        ${xl(`
            font-size:20px;
            line-height:25px;
        `)}
         ${lg(`
            font-size:15px;
            line-height:19px;
        `)}
        ${md(`
            font-size:20px;
            line-height:25px;
        `)}
       }
    }
  }
  .resultVideoSection {
    width: 100%;
    position: relative;
    video{
      width: 100%;
      height: auto;
    }
    
  
  }
`
