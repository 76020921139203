/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { AdditionalappfeaturesecStyles } from './Additionalappfeaturesec.styles'
import Image from 'components/ImgOptimized'

function AnimationSecRow(props) {
  const [isVisible, setVisible] = React.useState(false)
  const secRef = React.useRef()

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true)
        observer.unobserve(secRef.current)
      }
    })
    observer.observe(secRef.current)

    return () => observer.unobserve(secRef.current)
  }, [])

  return (
    <div
      ref={secRef}
      className={`secRow ${isVisible ? 'is-visible' : 'is-hidden'}`}
    >
      <div className="secContentCol">
        <h3>{props.SubTitle}</h3>
        <p>{props.para}</p>
        {props.SubTitleTwo ? <h3>{props.SubTitleTwo}</h3> : ''}
        {props.paraTwo ? <p>{props.paraTwo}</p> : ''}
      </div>
      <div className="secImgCol">
        <Image
          fluid={props.itemsImg}
          lazyload={{
            enabled: true,
            once: true,
            offset: 1000,
            heigth: 411,
          }}
        />
      </div>
    </div>
  )
}

function Additionalappfeaturesec(props) {
  const {
    additionalFeatureData: { additionalFeatureProps },
  } = props
  const rowContentData = additionalFeatureProps.additionalFeatureRow
  return (
    <AdditionalappfeaturesecStyles className="additionalAppFeaturesec">
      <div className="container">
        {additionalFeatureProps.mainTitle ? (
          <div className="additionalAppfeatureHead">
            <h2>{additionalFeatureProps.mainTitle}</h2>
            <p>{additionalFeatureProps.para}</p>
          </div>
        ) : (
          ''
        )}
        <div className="mobielAppScreenDetails">
          {additionalFeatureProps.additionalFeatureDetails.map(
            (additionalFeaDataProps, i) => {
              return (
                <AnimationSecRow
                  key={i}
                  itemsImg={props.imgData[i]}
                  {...additionalFeaDataProps.rowContentData}
                />
              )
            }
          )}
        </div>
      </div>
    </AdditionalappfeaturesecStyles>
  )
}

export default Additionalappfeaturesec
