import React from 'react'
import { PartnerprofileStyles } from './Partnerprofile.styles'
import Picture from 'components/Picture/Picture'

function Partnerprofile({ partnerprofiledata }) {
  return (
    <PartnerprofileStyles className="Partnerprofile">
      <div className="container">
        <div className="heading">
          <div className="title-part">
            <div className="subtitle">{partnerprofiledata.subTitle}</div>
            <h3>{partnerprofiledata.title}</h3>
          </div>
          <div className="logos-part">
            {partnerprofiledata.logos.map((logo, i) => {
              return (
                <figure key={i}>
                  <Picture
                    height={50}
                    offset={2000}
                    srcSet={logo.srcSet}
                    src={logo.src}
                    alt=""
                  />
                </figure>
              )
            })}
          </div>
        </div>
        <div className="statistics-blk">
          {partnerprofiledata.statisticProps.map((statisticProp, i) => {
            return (
              <div key={i} className="single-blk">
                <div className="count">{statisticProp.count}</div>
                <div className="title">{statisticProp.title}</div>
              </div>
            )
          })}
        </div>
      </div>
    </PartnerprofileStyles>
  )
}

export default Partnerprofile
