import React from 'react'
import LazyLoad from 'react-lazyload'
import { IhealthresultsecStyles } from './Ihealthresultsec.styles'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Videoplayer from 'components/Videoplayer/Videoplayer'

function Ihealthresultsec(props) {
  const { IhealthresultProps } = props.IhealthresultData
  const caseQuoteProp = IhealthresultProps.caseQuoteProps
  return (
    <IhealthresultsecStyles className="IhealthresultsecStyles">
      <div className="container">
        <div className="intro-video-section">
          {IhealthresultProps.videoURL ? (
            <Videoplayer videoURL={IhealthresultProps.videoURL} />
          ) : (
            <img src={IhealthresultProps.ImgURL} alt="" />
          )}
        </div>
        {IhealthresultProps.heading || IhealthresultProps.maincontent ? (
          <div className="result-sec">
            <div className="left-part">
              <h2>{IhealthresultProps.heading}</h2>
            </div>
            <div className="right-part">
              <p>{IhealthresultProps.maincontent}</p>
            </div>
          </div>
        ) : (
          ''
        )}
        {IhealthresultProps.resultAppList ? (
          <div className="appListRow">
            {IhealthresultProps.resultAppList.map((AppListProps, i) => {
              return (
                <div key={i} className="appList">
                  <div className="appIcon">
                    <LazyLoad height={50} once offset={1000}>
                      <img
                        src={AppListProps.IconUrl}
                        alt={AppListProps.IconAlt}
                      />
                    </LazyLoad>
                  </div>
                  <p>{AppListProps.shortDescription}</p>
                </div>
              )
            })}
          </div>
        ) : (
          ''
        )}
        {IhealthresultProps.resultImgURL ||
        IhealthresultProps.resultvideoURL ? (
          <div className="resultVideoSection">
            {IhealthresultProps.resultvideoURL ? (
              <video autoPlay muted loop playsInline>
                <source
                  src={IhealthresultProps.resultvideoURL}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img src={IhealthresultProps.resultImgURL} alt="" />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </IhealthresultsecStyles>
  )
}

export default Ihealthresultsec
