import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { roundrProps } from 'components/Props/roundr'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md, sm } from 'config/variables'
import Locaranimationbanner from 'components/Locaranimationbanner/Locaranimationbanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Partnerprofile from 'components/Partnerprofile/Partnerprofile'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Casecloudbasedsec from 'components/Casecloudbasedsec/Casecloudbasedsec'
import Caseteamingupsec from 'components/Caseteamingupsec/Caseteamingupsec'
import Ihealthresultsec from 'components/Ihealthresultsec/Ihealthresultsec'
import Additionalappfeaturesec from 'components/Additionalappfeaturesec/Additionalappfeaturesec'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Automatingsec from 'components/Automatingsec/Automatingsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Mobileappdrivercontrol from 'components/Mobileappdrivercontrol/Mobileappdrivercontrol'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
export const RoundrPage = styled.div`
  .caseAnimationBanner {
    padding-bottom: 120px; 
    ${xl(`
       padding-bottom: 90px;
    `)}
    ${lg(`
       padding-bottom: 60px; 
    `)}
    ${md(`
       padding-bottom: 40px; 
    `)} 
    .img-block { 
      .bannerImage {
        width: 649px;
        height:537px;
        position: relative;
        ${xl(`
          width: 460px;
          height: 370px;
        `)}
        ${lg(`
            width: 351px;
            height: 300px;
        `)}
        ${sm(`
           width: 280px;
           height: 230px;
        `)}
        img {
          position:absolute;
          &.introMobileScreen {
            top:0;
            right:-13px; 
           
            ${xl(`
                max-width: 376px;
            `)}
            ${lg(`
                 max-width: 300px;
            `)}
            ${sm(`
               max-width: 230px;
            `)}
          }   
          &.actionLinkBtn { 
            bottom: 56px;
            right: -33px;
            animation: floatx 8s ease-in-out infinite;
            ${xl(`
                max-width: 260px;
                bottom: 31px;
            `)}
            ${lg(`
                max-width: 200px;
                bottom: 30px;
            `)}
            ${sm(`
                max-width: 160px;
                bottom: 20px;
                right: -20px;
            `)}
          }   
          &.actionAddBtn {
            bottom: 113px;
            left: 10px;
            transform: translatex(0px);
	          animation: floatx 8s ease-in-out infinite;
            ${xl(`
                max-width: 170px;
                bottom: 80px;
            `)}
            ${lg(`
                max-width: 135px;
                bottom: 64px;
            `)}
            ${sm(`                
              max-width: 105px;
              bottom: 49px;
            `)}
          }   
          &.ntroHomeImg {
            left: 0;
            bottom: 0; 
	          animation: floatWithShadw 8s ease-in-out infinite;  
            z-index: 1;
            ${xl(`
                max-width: 320px;
            `)}
            ${lg(`
                max-width: 260px;
            `)}
            ${sm(`
                max-width: 205px;
            `)}
          }   
        }        
      }
    }
  }
  .Partnerprofile  {
    .heading {
      .title-part {
        h3 {
          color:#4aa49c;
        }
      }
    } 
  }
  .engagementSec {
    .mobileAppsheading  {
      h4 {
        margin-left: 0;
        display:inline-block;
        background:#fff;
        position: relative;
        z-index:10;
        padding-right:30px;
      }
    }
    .mobileAppsContent {
      .mobileAppsContentLeft {
        padding:0px;
      }
    }
  }
  .IhealthresultsecStyles {
    margin-bottom: 93px; 
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)}
    .intro-video-section {
      margin:0px;
    }
  } 
  .functionalSolution {
    .casepower-section {
      padding:70px 0;
      background-image: linear-gradient(to bottom, #00a79d, #1f2f44 134%);
      ${lg(`
        padding:50px 0;
      `)}
      ${md(`
        padding:40px 0; 
      `)}
      .CasePowerToolsMainTitle {
        h2 {
          color:#fff;
          margin-bottom:20px;
        }
      }
      .powerContentRow {
        .powerToolsDetails {
          color:#fff;
        }
      }
    } 
  }
  .automatingSec {
    margin-bottom: 120px; 
    padding-bottom:0px;
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)}
    &::after {
      display:none;
    }
  }
  .desktopAppsSec {
      .casepower-section {
        .powerContentSecRow {
          .powerContentRow {
          align-items:center;
        }
      }
    }
  }
  .tmsDataScreensSec {
    .Casecontentfullwimg {
      padding:100px 0; 
      background-image: linear-gradient(to bottom, #00a79d, #1f2f44 134%);
      ${xl(`
        padding:80px 0;
      `)}
      ${lg(`
        padding:60px 0;
      `)}
      ${md(`
        padding:40px 0;
      `)} 
      figure {
        margin:0px;
      }
    }
  }
  .MobileappdrivercontrolSec {
    margin-bottom: 120px;
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)} 
      .mobileAppsContent {
        .mobileAppsContentLeft {
          .mobileAppsTitle {
            &::before {
              display:none;
            }
          }
        }
      } 
  } 
  @keyframes floatWithShadw {
    0% { 
      transform: translatey(0px);
      filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.4)); 
    }
    50% { 
      transform: translatey(-15px);
      filter: drop-shadow(0 20px 20px rgba(0,0,0,0.4)); 
    }
    100% { 
      transform: translatey(0px);
       filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.4)); 
    }
  }       
`
const Roundr = props => {
  const { data } = props
  const { location } = props

  let secTopImgRow = [
    props.data.secTopImgRowOne.childImageSharp.fluid,
    props.data.secTopImgRowTwo.childImageSharp.fluid,
    props.data.secTopImgRowThree.childImageSharp.fluid,
  ]
  const rightDetailsImg = props.data.rightDetailsImg.childImageSharp.fluid
  const teamBackImg = props.data.teamBackImg.childImageSharp.fluid
  let featureImg = [
    props.data.innovateScreen.childImageSharp.fluid,
    props.data.inbuiltmapScreen.childImageSharp.fluid,
    props.data.communicationScreen.childImageSharp.fluid,
    props.data.trackingScreen.childImageSharp.fluid,
    props.data.collaborateScreen.childImageSharp.fluid,
    props.data.cloudScreen.childImageSharp.fluid,
  ]
  let featuresItem = [props.data.functionalSolutionScreen.childImageSharp.fluid]
  let desktopAppsImg = [props.data.desktopAppsImg.childImageSharp.fluid]
  const stepSlowScreens = props.data.stepSlowScreens.childImageSharp.fluid
  const scalabilityDiagram = props.data.scalabilityDiagram.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Roundr"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <RoundrPage>
        <Locaranimationbanner bannerdata={roundrProps} />
        <Caseintroduction
          caseintrodata={roundrProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Partnerprofile
            partnerprofiledata={roundrProps.PartnerProfileProps}
          />
          <Engagementsec
            engagementsecdata={roundrProps}
            {...props}
            showFullimages={false}
          />
        </div>
        {showContent && (
          <>
            <Casecloudbasedsec
              CasecloudbasedsecData={roundrProps}
              secTopImgRow={secTopImgRow}
              rightDetailsImg={rightDetailsImg}
            />
            <Caseteamingupsec
              caseTeamingUpData={roundrProps}
              teamBackImg={teamBackImg}
            />
            <Ihealthresultsec IhealthresultData={roundrProps} />
            <Additionalappfeaturesec
              additionalFeatureData={roundrProps}
              imgData={featureImg}
            />
            <div className="functionalSolution">
              <CasePowerTools
                CasePowerToolsdata={roundrProps.functionalSolutionProps}
                items={featuresItem}
              />
            </div>
            <Automatingsec
              Automatingsecdata={roundrProps}
              {...props}
              showFullimages="true"
            />
            <div className="desktopAppsSec">
              <CasePowerTools
                CasePowerToolsdata={roundrProps.desktopAppsSecProps}
                items={desktopAppsImg}
              />
            </div>
            <div className="tmsDataScreensSec">
              <Casecontentfullwimg
                casecontentfullwdata={roundrProps.casecontentfullwProps}
                fluid={stepSlowScreens}
              />
            </div>
            <Mobileappdrivercontrol
              Mobileappdrivercontroldata={roundrProps}
              {...props}
              diagramImg={scalabilityDiagram}
            />
            <Relatedcaselist relatedcaselistdata={roundrProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={roundrProps} />
          </>
        )}
      </RoundrPage>
    </Layout>
  )
}

export default Roundr

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/rounder-intro@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(
      relativePath: { regex: "/fts-challenges-img1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 981) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    rightDetailsImg: file(
      relativePath: { regex: "/roundr-add-property-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 747) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    secTopImgRowOne: file(
      relativePath: { regex: "/roundr-schedule-viewing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 226) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    secTopImgRowTwo: file(
      relativePath: { regex: "/roundr-managing-seller@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 226) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    secTopImgRowThree: file(
      relativePath: { regex: "/roundr-paper-intensive@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 226) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    teamBackImg: file(relativePath: { regex: "/roundr-result-home.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    innovateScreen: file(
      relativePath: { regex: "/roundr-innovate-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    inbuiltmapScreen: file(
      relativePath: { regex: "/roundr-inbuiltmap-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    communicationScreen: file(
      relativePath: { regex: "/roundr-communication-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    trackingScreen: file(
      relativePath: { regex: "/roundr-tracking-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    collaborateScreen: file(
      relativePath: { regex: "/roundr-collaborate-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    cloudScreen: file(
      relativePath: { regex: "/roundr-cloud-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    functionalSolutionScreen: file(
      relativePath: { regex: "/roundr-functional-solution@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 431) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    deploymentAutomationImage: file(
      relativePath: { regex: "/rounder-platform-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1047) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    desktopAppsImg: file(
      relativePath: { regex: "/roundr-desktop-app-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 626) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stepSlowScreens: file(
      relativePath: { regex: "/roundr-step-slow-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1179) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    scalabilityDiagram: file(
      relativePath: { regex: "/roundr-architecture-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1043) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/mirabies-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/accent-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
